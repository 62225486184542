import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import IdleTimer from 'react-idle-timer';
import '../../services/StringExtensions';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import adminNav from '../../nav-admin';
import customerNav from '../../nav-customer';
import cookie from 'react-cookies';
// routes config
import routes from '../../routes';

import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

const Areas = ['admin','customer'];

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.activeNav = adminNav;
    this.idleTimer = null;
    
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);

    this.home = '/';
  }

  onAction(e) {
    // keep the session alive. the server is doing the same thing when api calls
    // are made.
    var token = cookie.load('token');
    if (token) {
      var expire = new Date();
      expire.setTime(expire.getTime()+(30*60*1000));
      cookie.save('token', token, {path: '/', expires: expire});  
    }
  }

  onActive(e) {
  }

  onIdle(e) {
    cookie.remove('token');
    if (this.props.history) this.props.history.push('/signin');
  }

  setHome() {
    var session = JSON.parse(sessionStorage.getItem('session'));
    if (session) {
      if (session.canConfigure || session.canManageStudioAccount) {
        this.home = '/admin';
        if (session.canManageStudioAccount) {
          let salesMenu = adminNav.items.find(i => i.name === 'Sales');
          if (salesMenu && !salesMenu.children.find(c => c.name === 'Monthly Sales')) {
            salesMenu.children.push(
              {
                name: 'Monthly Sales',
                url: '/admin/usage/monthlysales',
                icon: 'fa fa-dollar',
              }
            )
          }
        }
        this.activeNav = adminNav;
      } else if (!session.securityZoneId.isDefaultGuid()) {
        this.home = '/customer';
        this.activeNav = customerNav;
      } else {
        // the security zone id is default, implying an admin, but the necessary permissions
        // aren't set, as they would be on all admins, so something has gone wrong here.
        if (this.props.history) this.props.history.push('/404');
      }
    }
  }

  render() {
    this.setHome();
    return (
      <div className="app">
        <IdleTimer
          ref={ref => { this.idleTimer = ref}}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={30*60*1000} />
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={this.activeNav} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes}/>
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to={this.home} />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
