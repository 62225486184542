import React from 'react';
import PalladientComponent from '../../PalladientComponent';
import PropTypes from 'prop-types';
import {    Button, Table,
             Row, Col, CardHeader, Card, CardBody,
            Form, FormGroup, Input, Label, Alert } from 'reactstrap';
import { CustomerProvider, ProductProvider, CustomerContractProvider } from '../../providers';
import { formatUsCurrency } from '../../services/DataTableHelper';
import { ProductCostBasisEnum, DiscountStrategyEnum } from '../../enums';
import { PrintButton } from '../../components';
import moment from 'moment';
import AgreementTermsCard from './AgreementTermsCard';

const propTypes = {
    customer: PropTypes.object.isRequired,
    contract: PropTypes.object.isRequired,
    agreementTerms: PropTypes.object.isRequired,
    primarySubscription: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    invoiceLocation: PropTypes.object.isRequired,
    onAccepted: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    inReadMode: PropTypes.bool,
}

class SubscriptionAgreementCard extends PalladientComponent {
    constructor(props) {
        super(props);

        this.defineState({
            confirmOrder: false,
        });

        this.products = [];
        this.contract = {};
        this.invoiceLocation= {};

        this.customerProvider = new CustomerProvider();
        this.productProvider = new ProductProvider();
        this.contractProvider = new CustomerContractProvider();

        this.handleAcceptClicked = this.handleAcceptClicked.bind(this);
    }

    handleAcceptClicked(e) {
        e.preventDefault();
        if (!this.state.confirmOrder) {
            this.showAlert("You must check the box to provide consent before continuing.", "warning");
        } else {
            this.hideAlert();
            if (this.props.onAccepted) this.props.onAccepted();
        }
    }

    getProduct(productId) {
        const product = this.props.products.find(p => p.id === productId);
        return product ? product : {};
    }

    getPriceTextForProduct(product) {
        if (product.costBasis === ProductCostBasisEnum.FixedPrice) {
            return "{0}/mo".format(formatUsCurrency(product.basePrice));
        } else if (product.costBasis === ProductCostBasisEnum.Consumption) {
            var text = "{0}/{1}/mo".format(formatUsCurrency(product.tiers[0].perUnitCost), product.consumptionCostUnit);
            if (product.tiers.length > 1) text += " ({0} more price tiers)".format(product.tiers.length-1);
            return text;
        } else {
            var text = "Base {0}/mo + {1}/{2} over {3}".format(formatUsCurrency(product.basePrice), formatUsCurrency(product.tiers[0].perUnitCost), product.consumptionCostUnit, product.consumptionCostThreshold);
            if (product.tiers.length > 1) text += " ({0} more price tiers)".format(product.tiers.length-1);
            return text;
        }
    }

    getDiscountText(subscription) {
        if (subscription.discountStrategy === DiscountStrategyEnum.None) {
            return '';
        } else if (subscription.discountStrategy === DiscountStrategyEnum.FixedAmount) {
            return 'Fixed price of ' + formatUsCurrency(subscription.discountAmount);
        } else if (subscription.discountStrategy === DiscountStrategyEnum.FixedReduction) {
            return '{0} off each month'.format(formatUsCurrency(subscription.discountAmount));
        } else {
            return '{0}% off each month'.format(subscription.discountAmount);
        }
    }

    renderSubscriptionFees(productId) {
        const product = this.props.products.find(p => p.id === productId);
        if (product) {
            if (product.code === 'NBL') {
                return (
                    <>
                        <p>
                            {product.description}
                        </p>
                        <p>
                            The table below represents standard per-user pricing for the Noteable EHR Platform. Any modifications to
                            these terms will be shown below. <strong>Once active, you will be charged the minimum base price each
                            month, even if you do not have active users in that month.</strong>
                        </p>
                        {this.renderFeeTable()}
                    </>
                )
            } else if (product.code === 'NBL:ST') {
                return (
                    <>
                        <p>
                            {product.description}
                        </p>
                        <p>
                            $250/month for first 5 users. $50/month for users 6 - 10. Once 11th user is added, pricing will switch to standard.
                        </p>
                        <p>
                            Once startup pricing no longer applies, the fee table below represents standard per-user pricing.
                            <strong>Once active, you will be charged the minimum base price each month, even if you do not 
                            have active users in that month.</strong>
                        </p>
                        {this.renderFeeTable()}
                    </>
                )
            } else if (product.code === 'NBL:GH') {
                return (
                    <>
                        <p>
                            {product.description}
                        </p>
                        <p>
                            $50 per bed per month. Indicate number of beds in notes. If this number changes you MUST notify us or be in breach of this agreement.
                        </p>
                    </>
                )
            } else if (product.code === 'NBL:ADS') {
                return (
                    <>
                        <p>
                            $240 per month for the first 20 clients. $12 per client, per month, for additional clients.
                        </p>
                    </>
                )
            }
        }
    }

    renderFeeTable() {
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th>Active Users</th>
                        <th>Monthly</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Base Price</td>
                        <td>$500, up to 10 users</td>
                    </tr>
                    <tr>
                        <td>Users 11 - 20</td>
                        <td>$40.00/user</td>
                    </tr>
                    <tr>
                        <td>Users 21 - 30</td>
                        <td>$30.00/user</td>
                    </tr>
                    <tr>
                        <td>Users 31 - 40</td>
                        <td>$20.00/user</td>
                    </tr>
                    <tr>
                        <td>Users 41 and up</td>
                        <td>$10.00/user</td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    renderAddonDetails() {
        if (this.props.contract.addonProductIds) {
            let addons = this.props.contract.addonProductIds.map(a => {
                const product = this.props.products.find(p => p.id === a);
                return product;
            });
    
            return (
                <>
                    <ul>
                        {
                            addons.map((addon, index) => (
                                <li key={addon.id}>
                                    {addon.name} - {addon.description} - {this.getPriceTextForProduct(addon)}
                                </li>
                            ))
                        }
                    </ul>
                </>
            )    
        }

        return '';
    }

    render() {
        let customer = this.props.customer,
            contract = this.props.contract,
            invoiceLocation = this.props.invoiceLocation;
        return (
            <>
                <Row>
                    <Col className="text-right">
                        <PrintButton
                        target="subscription-agreement-content"
                        windowTitle="Noteable Subscription Agreement"
                        color="primary"
                        height={800}
                        width={900}
                        className="ml-auto mb-2"
                        bodyClasses="m-4"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody id="subscription-agreement-content">
                                <Row>
                                    <Col className="text-center">
                                        <h5>Noteable Order Form</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table borderless size="sm">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Customer:</strong></td>
                                                    <td>{customer.name}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Authorized Person:</strong></td>
                                                    <td>{customer.billingContactName}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Email Address:</strong></td>
                                                    <td>{customer.billingEmail}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Billing Address:</strong></td>
                                                    <td>
                                                        {invoiceLocation.addressLine1}<br/>
                                                        {invoiceLocation.addressLine2 ? (invoiceLocation.addressLine2 + '</br>') : ''}
                                                        {invoiceLocation.city}, {invoiceLocation.stateOrRegion} {invoiceLocation.postalCode}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="text-muted"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table borderless size="sm">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Initial Services:</strong></td>
                                                    <td>
                                                        <p>
                                                            Initial service fees for the setup of your Noteable account include:
                                                        </p>
                                                        <ol>
                                                            <li>Support on the general configuration of your tenancy on Noteable, including program settings and billing rules</li>
                                                            <li>Initial implementation of report templates</li>
                                                            <li>One administrator training, virtual</li>
                                                            <li>One supervisor training, virtual</li>
                                                            <li>One clinician/counselor training, virtual</li>
                                                            <li>Expert business analysis and consulting</li>
                                                        </ol>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Initial Service Fees:</strong></td>
                                                    <td>{formatUsCurrency(contract.upfrontCost)}</td>
                                                </tr>
                                                <tr>
                                                    <td width="180px"><strong>Base Subscription Type:</strong></td>
                                                    <td>{this.getProduct(contract.initialProductId).name}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>{this.getProduct(contract.initialProductId).description}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Subscription Fees:</strong></td>
                                                    <td>
                                                        {this.renderSubscriptionFees(contract.initialProductId)}
                                                        {
                                                            this.props.primarySubscription.discountStrategy !== DiscountStrategyEnum.None ? (
                                                                <p>
                                                                    <strong>Discount applied: </strong>
                                                                    {this.getDiscountText(this.props.primarySubscription)}
                                                                </p>
                                                            ) : ''
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Addons:</strong></td>
                                                    <td>
                                                        {this.renderAddonDetails()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Custom Terms:</strong></td>
                                                    <td>{contract.additionalTerms}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Go-live Date:</strong></td>
                                                    <td>
                                                        <p>
                                                            {moment(contract.contractedGoLiveDate).format('MM-DD-yyyy')}
                                                        </p>
                                                        <p>
                                                            By checking the box below, you agree that your subscription will be considered
                                                            "active", and you will begin receiving invoices, at the end of the month indicated
                                                            here, or, if the date shown is on or after the 20th of the month, then the following month.
                                                        </p>    
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <hr className="text-muted"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AgreementTermsCard agreementTerms={this.props.agreementTerms} />
                                    </Col>
                                </Row>
                                {
                                    !this.props.inReadMode ? (
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Form>
                                                        <FormGroup check className="mb-2">
                                                            <Label check>
                                                                <Input type="checkbox" id="confirmOrder" checked={this.state.confirmOrder} onChange={this.handleChange} />{' '}
                                                                I, {customer.billingContactName}, an authorized agent of {customer.name}, have reviewed and consent to the terms listed above.
                                                            </Label>
                                                        </FormGroup>
                                                        <hr className="text-muted"/>
                                                        <Row form>
                                                            <Col className="text-center">
                                                                <Button color="primary" size="lg" disabled={this.props.buttonDisabled} onClick={this.handleAcceptClicked}>Accept</Button> 
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <Col>
                                                    <Alert color={this.state.alertColor} isOpen={this.state.alertVisible} toggle={this.hideAlert}>{this.state.alertMessage}</Alert>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col className="text-center">
                                                    <span className="font-weight-bold"><small>Agreement accepted {moment(this.props.contract.customerSignedOn).format('MM/DD/yyyy')} by {this.props.contract.customerSignerEmail}</small></span>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

SubscriptionAgreementCard.propTypes = propTypes;

export default SubscriptionAgreementCard;