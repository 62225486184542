import React from 'react';
import { Container, Row } from 'reactstrap';
import Loadable from 'react-loadable'

function Loading() {
  return (
    <Container>
      <Row className="justify-content-center">
          <div className="sk-wave">
          <div className="sk-rect sk-rect1"></div>&nbsp;
          <div className="sk-rect sk-rect2"></div>&nbsp;
          <div className="sk-rect sk-rect3"></div>&nbsp;
          <div className="sk-rect sk-rect4"></div>&nbsp;
          <div className="sk-rect sk-rect5"></div>
          </div>
      </Row>
      <Row className="justify-content-center">
          <p>Loading...</p>
      </Row>
    </Container>
  );
}

const AdminHome = Loadable({
  loader: () => import('./views/Admin/AdminHome'),  
  loading: Loading,
});

const ProductsPage = Loadable({
  loader: () => import('./views/Admin/Products/ProductsPage'),
  loading: Loading,
});

const EditProductPage = Loadable({
  loader: () => import('./views/Admin/Products/EditProductPage'),
  loading: Loading,
});

const CustomersPage = Loadable({
  loader: () => import('./views/Admin/Customers/CustomersPage'),
  loading: Loading,
});

const CustomerPage = Loadable({
  loader: () => import('./views/Admin/Customers/CustomerPage'),
  loading: Loading,
});

const CustomerHome = Loadable({
  loader: () => import('./views/Customer/CustomerDashboardPage'),
  loading: Loading,
});

const MakeAPaymentPage = Loadable({
    loader: () => import('./views/Customer/MakeAPaymentPage'),
    loading: Loading,
});

const MyTrainingsPage = Loadable({
  loader: () => import('./views/Customer/MyTrainingsPage'),
  loading: Loading,
});

const ProfilePage = Loadable({
  loader: () => import('./views/Profile/ProfilePage'),
  loading: Loading,
});

const ImportUsagePage = Loadable({
  loader: () => import('./views/Admin/Usage/ImportUsagePage'),
  loading: Loading,
});

const BrowseUsagePage = Loadable({
  loader: () => import('./views/Admin/Usage/BrowseUsagePage'),
  loading: Loading,
});

const MonthlySalesPage = Loadable({
  loader: () => import('./views/Admin/Usage/MonthlySalesPage'),
  loading: Loading,
});

const SalesDashboardPage = Loadable({
  loader: () => import('./views/Admin/Usage/SalesDashboardPage'),
  loading: Loading,
});

// const PricingChangeImpactPage = Loadable({
//   loader: () => import('./views/Admin/Usage/PricingChangeImpactPage'),
//   loading: Loading,
// });

const CustomerSubscriptionsPage = Loadable({
  loader: () => import('./views/Admin/Customers/CustomerSubscriptionsPage'),
  loading: Loading,
});

const CustomerTrainingsPage = Loadable({
  loader: () => import('./views/Admin/Customers/CustomerTrainingsPage'),
  loading: Loading,
});

const CustomerNotesPage = Loadable({
  loader: () => import('./views/Admin/Customers/CustomerNotesPage'),
  loading: Loading,
});

const CustomerLocationsPage = Loadable({
  loader: () => import('./views/Admin/Customers/CustomerLocationsPage'),
  loading: Loading,
});

const InvoicesPage = Loadable({
  loader: () => import('./views/Admin/Usage/InvoicesPage'),
  loading: Loading,
});

const InvoicePage = Loadable({
  loader: () => import('./views/Admin/Usage/InvoicePage'),
  loading: Loading,
});

const SettingsPage = Loadable({
  loader: () => import('./views/Admin/SettingsPage'),
  loading: Loading,
});

const ReleasesPage = Loadable({
  loader: () => import('./views/Admin/Communications/ReleaseAnnouncementsPage'),
  loading: Loading,
});

const PastInvoicesPage = Loadable({
  loader: () => import('./views/Customer/PastInvoicesPage'),
  loading: Loading,
});

const AccountSettingsPage = Loadable({
  loader: () => import('./views/Customer/AccountSettingsPage'),
  loading: Loading,
});

const InternalUsersPage = Loadable({
  loader: () => import('./views/Admin/Users/InternalUsersPage'),
  loading: Loading,
});

const AddonsPage = Loadable({
  loader: () => import('./views/Customer/AddonsPage'),
  loading: Loading,
});

const CustomerSupportPage = Loadable({
  loader: () => import('./views/Customer/CustomerSupportPage'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/admin', name: "Home", component: AdminHome, exact: true },
  { path: '/admin/settings', name: "Settings", component: SettingsPage, exact: true },
  { path: '/admin/products', name: "Products", component: ProductsPage, exact: true },
  { path: '/admin/products/:productId', name: "Product", component: EditProductPage, exact: true },
  { path: '/admin/customers', name: "Customers", component: CustomersPage, exact: true },
  { path: '/admin/customers/:customerId', name: "Customer", component: CustomerPage, exact: true },
  { path: '/admin/customers/:customerId/subscriptions', name: "Subscriptions", component: CustomerSubscriptionsPage, exact: true },
  { path: '/admin/customers/:customerId/trainings', name: "Trainings", component: CustomerTrainingsPage, exact: true },
  { path: '/admin/customers/:customerId/notes', name: "Notes", component: CustomerNotesPage, exact: true },
  { path: '/admin/customers/:customerId/locations', name: "Locations", component: CustomerLocationsPage, exact: true },
  { path: '/customer', name: "Home", component: CustomerHome, exact: true },
  { path: '/customer/payments', name: "Payments", component: MakeAPaymentPage, exact: true },
  { path: '/customer/invoices', name: "Invoices", component: PastInvoicesPage, exact: true },
  { path: '/customer/trainings', name: "My Trainings", component: MyTrainingsPage, exact: true },
  { path: '/customer/settings', name: "Account Settings", component: AccountSettingsPage, exact: true },
  { path: '/customer/addons', name: "My Addons", component: AddonsPage, exact: true},
  { path: '/customer/support', name: "Support Center", component: CustomerSupportPage, exact: true},
  { path: '/profile', name: "Profile", component: ProfilePage, exact: true },
  { path: '/admin/usage/import', name: "Import Usage", component: ImportUsagePage, exact: true },
  { path: '/admin/usage/browse', name: "Browse Usage", component: BrowseUsagePage, exact: true },
  // { page: '/admin/usage/pricechangeimpact', name: "Pricing Change Impact", component: PricingChangeImpactPage, exact: true },
  { path: '/admin/usage/salesdashboard', name: "Sales Dashboard", component: SalesDashboardPage, exact: true },
  { path: '/admin/usage/monthlysales', name: "Monthly Sales", component: MonthlySalesPage, exact: true },
  { path: '/admin/usage/invoices', name: "Manage Invoices", component: InvoicesPage, exact: true },
  { path: '/admin/usage/invoices/:invoiceId', name: "Invoice", component: InvoicePage, exact: true },
  { path: '/admin/communications/releases', name: "Manage Release Announcements", component: ReleasesPage, exact: true },
  { path: '/admin/users', name: "Manage Internal Users", component: InternalUsersPage, exact: true },
];

export default routes;
